<template>
  <div id="map"></div>

  <Header
    :regionTypes="regionTypes"
    @region-change="store.changeRegionType($event, changeRegionVisibility)"
    data-html2canvas-ignore
  ></Header>

  <div
    class="menu"
    data-html2canvas-ignore
  >
    <Tab1
      v-for="(tab, index) of tabs"
      :children="tab.children"
      :active="isActive(tab)"
      :index="index"
      @click="store.chooseMenuTabId(tab?.id, index)"
    >
      <template v-if="tab.icon == 'crop-yield.svg'">
        <CropYieldSvg />
      </template>
      <template v-else-if="tab.icon == 'crop-area.svg'">
        <CropAreaSvg />
      </template>
      <template v-else-if="tab.icon == 'fertilizers.svg'">
        <FertilizersSvg />
      </template>
      <template v-else-if="tab.icon == 'irrigation.svg'">
        <IrrigationSvg />
      </template>
      {{ tab.name?.[chosenLang.id] || tab.name }}
    </Tab1>

    <RegionType
      :regionTypes="regionTypes"
      @change="store.changeRegionType($event, changeRegionVisibility)"
    ></RegionType>
  </div>

  <Timeline
    v-if="canInitOtherComponents"
    data-html2canvas-ignore
  ></Timeline>

  <Legend v-if="canInitOtherComponents"></Legend>

  <div
    class="subpanel"
    data-html2canvas-ignore
  >
    <IconButton
      :inverted="true"
      @click="zoom('+')"
    >+</IconButton>

    <IconButton
      :inverted="true"
      @click="zoom('-')"
    >-</IconButton>
  </div>

  <div
    id="save"
    data-html2canvas-ignore
  >
    <IconButton
      :inverted="false"
      @click="saveImg"
      id="saveImg"
    >
      <img
        src="@/assets/images/save-img.svg"
        alt="save-image-icon"
      />
    </IconButton>
    <IconButton
      :inverted="false"
      @click="saveCSV"
    >
      <img
        src="@/assets/images/save-csv.svg"
        alt="save-csv-icon"
      />
    </IconButton>
  </div>

  <Chart
    v-if="canInitOtherComponents"
    ref="chartRef"
    :chosenRegion="chosenRegion"
    @close="onClose"
  ></Chart>

  <PopupAbout />
  <ParamsPopup />
  <MeasurmentPopup />

  <div id="cloudflareWidget"></div>

  <div id="logo-screenshot">
    <div class="name">
      <img
        src="@/assets/images/logo1.png"
        class="name__logo"
      />
      <div class="name__text">
        <h2 class="name__name">
          AgroStats
        </h2>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineAsyncComponent } from "vue";
// components
import Header from '@/components/Header.vue'
import Tab1 from '@/components/Tabs/tab-1.vue'
import IconButton from '@/components/Icon-button.vue'
import RegionType from '@/components/Region-type.vue'
import PopupAbout from "@/components/About-popup.vue";

// local data
import tabs from "@/assets/content/tabs.json"
import regions from "@/assets/content/regions.json"

// everything else
import { useMapStore } from '@/stores/useMapStore'
import { Map, Popup } from '@maptiler/sdk'
import { computed, onMounted, ref, watch } from 'vue'
import { useLangStore } from './stores/useLangStore'
import { useWidget } from "@/composables/cloudflareWidget"
import ParamsPopup from "@/components/Params-popup.vue";

// svg components
import CropAreaSvg from "@/components/svg/crop-area.vue"
import CropYieldSvg from "@/components/svg/crop-yield.vue"
import FertilizersSvg from "@/components/svg/fertilizers.vue"
import IrrigationSvg from "@/components/svg/irrigation.vue"
import MeasurmentPopup from "./components/Measurment-popup.vue";

// async imports
const Timeline = defineAsyncComponent({
  loader: () => import('./components/Timeline.vue'),
  delay: 0
})
const Legend = defineAsyncComponent({
  loader: () => import('./components/Legend.vue'),
  delay: 0
})
const Chart = defineAsyncComponent({
  loader: () => import('./components/Chart.vue'),
  delay: 0
})

const store = useMapStore(),
  langStore = useLangStore()

let map, hoveredStateId = null,
  clickedStateId = null,
  chosenRegion = ref(),
  chosenRegionType = computed(() => store.chosenRegionTypeGetter), // 'regions_layer' or 'Ukraine_layer'
  regionTypes = [{
    name: {
      en: 'Admin. oblast\'',
      ua: 'Адмін. область'
    },
    value: 'regions_layer'
  }, {
    name: {
      en: 'Ukraine',
      ua: 'Україна'
    },
    value: 'Ukraine_layer'
  }],
  chartRef = ref(),
  chosenLang = computed(() => langStore.chosenLangGetter),
  canInitOtherComponents = ref(false)

const changeRegionVisibility = (region) => {
  if (region == 'Ukraine_layer') {
    map.setLayoutProperty('regions_layer', 'visibility', 'none')
    map.setLayoutProperty('regions_outline', 'visibility', 'none')
    map.setLayoutProperty('Ukraine_layer', 'visibility', 'visible')
  }
  else if (region == 'regions_layer') {
    map.setLayoutProperty('regions_layer', 'visibility', 'visible')
    map.setLayoutProperty('regions_outline', 'visibility', 'visible')
    map.setLayoutProperty('Ukraine_layer', 'visibility', 'none')
  }
}

const saveImg = async () => {
  const { domToPng } = await import('modern-screenshot');

  let dataUrl = await domToPng(document.body, {
    timeout: 1000,
    backgroundColor: "#000",
    filter: (el) => {
      return !el?.hasAttribute?.('data-html2canvas-ignore')
    },
    onCloneNode: (node) => {
      node.querySelector('noscript').style.display = 'none';
      node.style.translate = '-8px -8px';
      node.querySelector("#logo-screenshot").style.display = 'block';
    }
  })
  const link = document.createElement('a')
  link.download = `AgroStats_map-${store.mainTabNameGetter?.[chosenLang.value.id]}-${store.measurmentsGetter?.name?.[chosenLang.value.id]}_${store.yearsGetter.join('-')}.png`;
  link.href = dataUrl
  link.click()
}

const saveCSV = () => {
  let data = store.mapGetter.features.map(el => {
    let foundRegionInJson = regions.find(region => region.name.id == el.properties.NAME_UA)
    return {
      nameEng: foundRegionInJson?.name?.en,
      nameUa: foundRegionInJson?.name?.ua,
      value: el.properties.value_data
    }
  });
  const titleKeys = Object.keys(data[0])

  const refinedData = []
  refinedData.push(titleKeys)

  data.forEach(item => {
    refinedData.push(Object.values(item))
  })

  let csvContent = ''

  refinedData.forEach(row => {
    csvContent += row.join(',') + '\n'
  })

  var link = document.createElement('a');
  if (store.mainTabNameGetter?.[chosenLang.value.id] == store.measurmentsGetter?.name?.[chosenLang.value.id]) link.download = `AgroStats_map-${store.mainTabNameGetter?.[chosenLang.value.id]}_${store.yearsGetter.join('-')}.csv`;
  else link.download = `AgroStats_map-${store.mainTabNameGetter?.[chosenLang.value.id]}-${store.measurmentsGetter?.name?.[chosenLang.value.id]}_${store.yearsGetter.join('-')}.csv`;
  const blob = new Blob([csvContent], { type: 'text/csv' })
  link.href = window.URL.createObjectURL(blob);
  link.click();
}

const zoom = (sign) => {
  if (sign == '+') map.zoomIn()
  else if (sign == '-') map.zoomOut()
}

const isActive = (tab) => {
  if (!tab.children) {
    return !!tab.id.find(el => el.queue == store.chosenMenuTabIdGetter[0].queue)
  }

  let res = false;

  tab.children.map(el => {
    if (el.children) {
      el.children.map(child1 => {
        if (child1.children) {
          child1.children.map(child2 => {
            if (JSON.stringify(child2.id) == JSON.stringify(store.chosenMenuTabIdGetter)) res = true;
          })
        }
        else {
          if (JSON.stringify(child1.id) == JSON.stringify(store.chosenMenuTabIdGetter)) res = true;
        }
      })
    }
    else {
      if (JSON.stringify(el.id) == JSON.stringify(store.chosenMenuTabIdGetter)) res = true;
    }
  })

  return res;
}

watch(() => store.updateMapTriggerGetter, () => {
  let paintObject = ['#a0a0a0']
  for (let i = +store.scaleGetter.length - 1; i >= 0; i--) {
    let color;

    let shift = chosenRegionType.value == 'Ukraine_layer' ? 1 : 0;
    if (store.selectedTimeModeGetter == "Compare") shift = - (store.scaleGetter.length - 1) / 2 + (chosenRegionType.value == 'Ukraine_layer' ? 1 : 0) + 1;

    if (i == 9 + shift) color = '#08519c'
    else if (i == 8 + shift) color = '#3182bd'
    else if (i == 7 + shift) color = '#6baed6'
    else if (i == 6 + shift) color = '#bdd7e7'
    else if (i == 5 + shift) color = '#FFFFE5'
    else if (i == 4 + shift) color = '#FFF7BC'
    else if (i == 3 + shift) color = '#FEC44F'
    else if (i == 2 + shift) color = '#EC7014'
    else if (i == 1 + shift) color = '#993404'
    if (i == 1 && shift && store.selectedTimeModeGetter != "Compare") color = '#501c02';
    if (store.selectedTimeModeGetter == "Compare" && i == 0) break;

    if (!color) break;

    let val = +store.scaleGetter[i]
    // for '<'
    if (Number.isNaN(+store.scaleGetter[i])) {
      if (store.selectedTimeModeGetter == "Compare") val = -1e6 + 1
      else val = -1e-4;
    }

    if (i == store.scaleGetter.length - 1) {
      if (store.selectedTimeModeGetter == "Compare") paintObject.push(Math.max(Math.floor(val), -1e6 + 1), color)
      else paintObject.push(Math.max(Math.floor(val), -1e-4), color)
    }
    else paintObject.push(val, color)
  }

  map.setPaintProperty(chosenRegionType.value, 'fill-color', [
    'step',
    ['get', 'value_data'],
    ...paintObject
  ])
  map.setPaintProperty(chosenRegionType.value, 'fill-opacity', 1)
  let source;
  if (chosenRegionType.value == 'regions_layer') source = "regions";
  else if (chosenRegionType.value == 'Ukraine_layer') source = "Ukraine"
  map.getSource(source).setData(store.mapGetter);
})

const mainFunc = async () => {
  console.time();

  console.log("Хай живе %cУкраїнська Агроімперія!", 'color: blue; background-color: yellow; font-weight: bold; font-style: italic; font-size: 1.5em;');
  console.log("Long live the %cUkrainian Agro-empire!", 'color: blue; background-color: yellow; font-weight: bold; font-style: italic; font-size: 1.5em;');
  console.log("v1.0.0");

  console.timeLog();

  map = new Map({
    container: 'map', // container's id or the HTML element in which SDK will render the map
    style: `https://api.maptiler.com/maps/f8fe4743-7a03-4a80-abdb-2c24795465f7/style.json?key=Gxh7oMbyjL1mXV9L4ujE`,
    center: [31, 48.5],
    //maxBounds: [10, 35, 45, 60],
    zoom: 4.75, // starting zoom
    minZoom: 3.75,
    maxZoom: 6,
    compassControl: false,
    attributionControl: false,
    navigationControl: false,
    geolocateControl: false,
    language: 'latin',
    preserveDrawingBuffer: true
  })

  const popup = new Popup({
    closeButton: false,
    closeOnClick: false
  });

  map.on('load', async () => {
    console.timeLog();

    const Regions_geojson = await import("@/assets/geojson/test_agriculture_admin_obl_simplfied_0_01.geojson")

    map.addSource('regions', {
      type: 'geojson',
      data: Regions_geojson
    });

    map.addLayer({
      'id': 'regions_layer',
      'type': 'fill',
      'source': 'regions',
      'layout': {
        'visibility': 'visible'
      },
      'paint': {
        // starting state
        'fill-color': [
          "step",
          [
            "get",
            "value_data"
          ],
          "#a0a0a0",
          -0.0001,
          "#FFF7BC",
          20,
          "#FEC44F",
          30,
          "#EC7014",
          40,
          "#993404"
        ],
        'fill-opacity': 1
      }
    });

    map.addLayer({
      'id': 'regions_outline',
      'type': 'line',
      'source': 'regions',
      'layout': {
        'visibility': 'visible'
      },
      'paint': {
        'line-color': '#000',
        'line-width': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          2,
          0.25
        ]
      }
    });

    map.on('mousemove', 'regions_layer', (e) => {
      if (e.features.length > 0) {
        if (hoveredStateId !== null && clickedStateId != hoveredStateId) {
          map.setFeatureState(
            { source: 'regions', id: hoveredStateId },
            { hover: false }
          );
        }
        hoveredStateId = +e.features[0].id;
        map.setFeatureState(
          { source: 'regions', id: hoveredStateId },
          { hover: true }
        );
        let numberVal =
          e.features[0].properties.value_data >
            (store.selectedTimeModeGetter == "Compare" ? -1e6 : -1e-4) ?
            `${store.selectedTimeModeGetter == "Compare" && e.features[0].properties.value_data > 0 ? '+' : ''}${Number(e.features[0].properties.value_data).toFixed(1)} ${store.measurmentsGetter?.id[store.chosenMeasurmentIndexGetter].name?.[chosenLang.value.id]}` :
            chosenLang.value.id == 'en' ? 'no data' : 'дані відсутні';
        let addText = {
          en: "",
          ua: ""
        };
        if (store.selectedTimeModeGetter == "Compare") {
          let filteredData = store.allMapDataGetter?.[e.features[0]?.properties.NAME_UA]

          if (!filteredData) return

          if (filteredData[0][1] === null && filteredData.at(-1)[1] === null) addText = {
            'en': ` for chosen years`,
            'ua': ' для обраних років'
          }
          else if (filteredData[0][1] === null) addText = {
            'en': ` for ${filteredData[0][0]}`,
            'ua': ` для ${filteredData[0][0]}`
          }
          else if (filteredData.at(-1)[1] === null) addText = {
            'en': ` for ${filteredData.at(-1)[0]}`,
            'ua': ` для ${filteredData.at(-1)[0]}`
          }
          else {
            if (filteredData?.length > 1) addText = {
              'en': `<br/>${filteredData[0][0]} and ${filteredData.at(-1)[0]}`,
              'ua': `<br/>${filteredData[0][0]} та ${filteredData.at(-1)[0]}`
            }
            else if (filteredData?.length == 1) addText = {
              'en': `<br/>only in ${filteredData[0][0]}`,
              'ua': `<br/>тільки за ${filteredData[0][0]}`
            }
          }
        }
        popup.setLngLat([e.lngLat.lng, e.lngLat.lat]).setHTML(`<div class='maplibregl-popup-content-heading'>${regions.find(el => el.name.id == e.features[0].properties.NAME_UA).name[chosenLang.value.id]}</div>${numberVal}${addText[chosenLang.value.id]}`).addTo(map);
      }
    });

    map.on('mouseleave', 'regions_layer', () => {
      if (hoveredStateId !== null && clickedStateId != hoveredStateId) {
        map.setFeatureState(
          { source: 'regions', id: hoveredStateId },
          { hover: false }
        );
        hoveredStateId = null;
      }
      popup.remove();
    });

    map.on('click', 'regions_layer', (e) => {
      if (chartRef.value.isClosed) {
        map.setFeatureState(
          { source: 'regions', id: hoveredStateId },
          { hover: true }
        );
        chartRef.value.open()
        clickedStateId = hoveredStateId;
      }
      else if (chosenRegion.value == e.features[0].properties.NAME_UA) {
        map.setFeatureState(
          { source: 'regions', id: hoveredStateId },
          { hover: false }
        );
        chartRef.value.close()
        clickedStateId = null;
      }
      else {
        map.setFeatureState(
          { source: 'regions', id: clickedStateId },
          { hover: false }
        );
        clickedStateId = hoveredStateId;
      }

      chosenRegion.value = e.features[0].properties.NAME_UA;
    });

    const Ukraine_geojson = await import("@/assets/geojson/test_agriculture_all_ukraine_simplfied_0_01.geojson")

    map.addSource('Ukraine', {
      type: 'geojson',
      data: Ukraine_geojson
    })

    map.addLayer({
      'id': 'Ukraine_layer',
      'type': 'fill',
      'source': 'Ukraine',
      'layout': {
        'visibility': 'none'
      },
      'paint': {
        'fill-color': '#FFF7BC',
        'fill-opacity': 1
      }
    });

    map.on('mousemove', 'Ukraine_layer', (e) => {
      if (e.features.length > 0) {
        if (hoveredStateId !== null && clickedStateId != hoveredStateId) {
          map.setFeatureState(
            { source: 'Ukraine', id: hoveredStateId },
            { hover: false }
          );
        }
        hoveredStateId = +e.features[0].id;
        map.setFeatureState(
          { source: 'Ukraine', id: hoveredStateId },
          { hover: true }
        );
        let numberVal =
          e.features[0].properties.value_data >
            (store.selectedTimeModeGetter == "Compare" ? -1e6 : -1e-4) ?
            `${store.selectedTimeModeGetter == "Compare" && e.features[0].properties.value_data > 0 ? '+' : ''}${Number(e.features[0].properties.value_data).toFixed(1)} ${store.measurmentsGetter?.id[store.chosenMeasurmentIndexGetter].name?.[chosenLang.value.id]}` :
            chosenLang.value.id == 'en' ? 'no data' : 'дані відсутні';
        let addText = {
          en: "",
          ua: ""
        };
        if (store.selectedTimeModeGetter == "Compare") {
          let filteredData = store.allMapDataGetter?.[e.features[0]?.properties.NAME_UA]

          if (!filteredData) return

          if (filteredData[0][1] === null && filteredData.at(-1)[1] === null) addText = {
            'en': ` for chosen years`,
            'ua': ' для обраних років'
          }
          else if (filteredData[0][1] === null) addText = {
            'en': ` for ${filteredData[0][0]}`,
            'ua': ` для ${filteredData[0][0]}`
          }
          else if (filteredData.at(-1)[1] === null) addText = {
            'en': ` for ${filteredData.at(-1)[0]}`,
            'ua': ` для ${filteredData.at(-1)[0]}`
          }
          else {
            if (filteredData?.length > 1) addText = {
              'en': `<br/>, ${filteredData[0][0]} and ${filteredData.at(-1)[0]}`,
              'ua': `<br/>, ${filteredData[0][0]} та ${filteredData.at(-1)[0]}`
            }
            else if (filteredData?.length == 1) addText = {
              'en': `<br/>only in ${filteredData[0][0]}`,
              'ua': `<br/>тільки за ${filteredData[0][0]}`
            }
          }
        }
        popup.setLngLat([e.lngLat.lng, e.lngLat.lat]).setHTML(`<div class='maplibregl-popup-content-heading'>${regions.find(el => el.name.id == e.features[0].properties.NAME_UA).name[chosenLang.value.id]}</div>${numberVal}${addText[chosenLang.value.id]}`).addTo(map);
      }
    });

    map.on('mouseleave', 'Ukraine_layer', () => {
      if (hoveredStateId !== null && clickedStateId != hoveredStateId) {
        map.setFeatureState(
          { source: 'Ukraine', id: hoveredStateId },
          { hover: false }
        );
        hoveredStateId = null;
      }
      popup.remove();
    });

    console.timeLog();
  });

  await map.once('load');
  canInitOtherComponents.value = true;
  store.chooseMenuTabId(store.chosenMenuTabIdGetter, 0);
  console.timeLog();
  await import('@maptiler/sdk/dist/maptiler-sdk.css')
  console.timeEnd();

  const RobotoLight = new FontFace("Roboto", "url(./fonts/Roboto-Light.ttf)", {
    weight: 300,
    display: "swap"
  })
  const RobotoRegular = new FontFace("Roboto", "url(./fonts/Roboto-Regular.ttf)", {
    weight: 400,
    display: "swap"
  })
  const RobotoMedium = new FontFace("Roboto", "url(./fonts/Roboto-Medium.ttf)", {
    weight: 500,
    display: "swap"
  })
  const RobotoBold = new FontFace("Roboto", "url(./fonts/Roboto-Bold.ttf)", {
    weight: 700,
    display: "swap"
  })
  document.fonts.add(RobotoLight);
  document.fonts.add(RobotoRegular);
  document.fonts.add(RobotoMedium);
  document.fonts.add(RobotoBold);
}

onMounted(async () => {
  window.onloadTurnstileCallback = async function () {
    if (document.cookie.split(";").some((item) => item.trim().startsWith("APIToken=") && item.trim('').split('=')[1] !== 'undefined')) {
      document.querySelector("#cloudflareWidget").classList.add("hidden")
      return await mainFunc();
    }
    else {
      
      /* document.querySelector("#cloudflareWidget").classList.add("hidden")
      return await mainFunc(); */

      useWidget(async () => {
        if (!canInitOtherComponents.value) await mainFunc();
      })
    }
  };
})

const onClose = () => {
  map.setFeatureState(
    { source: 'regions', id: clickedStateId },
    { hover: false }
  );

  clickedStateId = null;
}
</script>

<style lang="scss">
/*
@font-face {
  font-family: Roboto;
  font-weight: 300;
  src: url("@/assets/fonts/Roboto/Roboto-Light.ttf");
  font-display: swap;
}

@font-face {
  font-family: Roboto;
  font-weight: 400;
  src: url("@/assets/fonts/Roboto/Roboto-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: Roboto;
  font-weight: 500;
  src: url("@/assets/fonts/Roboto/Roboto-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: Roboto;
  font-weight: 700;
  src: url("@/assets/fonts/Roboto/Roboto-Bold.ttf");
  font-display: swap;
}
*/

* {
  box-sizing: border-box;
}

body {
  position: relative;
  margin: 0;
  padding: 0;
  background-color: #505050;
  width: 100vw;
  height: 100dvh;
  overflow: hidden;
}

.menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: fit-content;
  padding: 32px 0 0 32px;
  pointer-events: none;
}

.subpanel {
  position: absolute;
  // 60px is header height - maybe fix later
  top: calc(60px + 30px);
  right: 30px;
  display: flex;
  flex-direction: column;
  gap: 5px;

  #layers {
    margin-top: 13px;
  }
}

#save {
  position: absolute;
  right: 30px;
  top: calc((100dvh - 75px) / 2);
  display: flex;
  flex-direction: column;
  gap: 5px;
}

#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100dvh;
}

.maplibregl-popup {
  max-width: 400px !important;
  font-family: Roboto;
  font-size: 18px;
  filter: drop-shadow(2px 4px 4px #000);
  margin-top: -20px;

  &-tip {
    border-top-color: #fff !important;
  }

  &-content {
    padding: 6px !important;
    background-color: #fff !important;
    line-height: 1.3;

    &-heading {
      margin-bottom: 8px;
      font-weight: 500;
    }
  }
}

#cloudflareWidget {
  position: absolute;
  width: 100vw;
  height: 100dvh;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000080;
  z-index: 1000;

  &.hidden {
    display: none;
    opacity: 0;
    z-index: -1000;
  }
}

// for content ref in Popup
.article {
  &__download {
    width: fit-content;
    background-color: #fff;
    padding: 12px 24px;
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border: 1px solid #141413a0;
    border-radius: 12px;
    cursor: pointer;
    transition: .3s;

    &:hover {
      background-color: #efefef;
      border-color: #000;
    }
  }

  a {
    text-decoration: unset;
    color: #0952ab;

    &:hover {
      text-decoration: underline;
      color: #001838;
    }
  }

  .partner-logo {
    height: 64px;

    &-container {
      margin-top: 24px;
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: flex-start;
      gap: 72px;
      align-items: center;
    }
  }

  #article-contacts {
    a {
      display: flex;
      flex-direction: row;
      gap: 12px;
      justify-content: center;
      align-items: center;
      width: fit-content;

      &>svg {
        width: 32px;
        height: 32px;
      }
    }
  }

  ol ul li {
    font-size: 16px;
  }

  ol li {
    margin: 4px 0;
  }
}

#logo-screenshot {
  position: absolute;
  display: none;
  height: 100px;
  top: 36px;
  left: 36px;

  .name {
    display: flex;
    gap: 0 12px;
    margin: auto;
    color: #fff;
    height: 36px;
    justify-content: center;
    align-items: center;

    &__logo {
      grid-area: logo;
      height: inherit;
    }

    &__name {
      grid-area: name;
      font-weight: 600;
      font-size: 26px;
      line-height: 17px;
      margin: unset;
    }

    &__tagline {
      grid-area: tagline;
      font-weight: 400;
      font-size: 13px;
      line-height: 13px;
      margin: unset;
    }
  }
}

@media screen and (max-width: 800px) {

  html,
  body {
    overflow: hidden;
  }

  .maplibregl-ctrl-bottom-right,
  .maplibregl-ctrl-bottom-left {
    display: none;
  }

  #saveImg {
    display: none;
  }

  .menu {
    display: none;
  }

  .subpanel {
    display: none;
  }

  #save {
    top: unset;
    bottom: 140px;

    .icon {
      width: 48px;
      height: 48px;
      padding: 10px;

      img {
        width: 100%;
      }
    }
  }

  .article {
    .partner-logo {
      &-container {
        flex-direction: column;
        gap: 32px;
      }
    }
  }
}
</style>
